/*************************************************
 * bodyに対するクリックイベントをiOSでも有効化
 ************************************************/

(function($) {
  // ----------------------------------------
  // Ready
  // ----------------------------------------
  $(function() {
    var condition = (navigator.userAgent.match(/(iPad|iPhone|iPod)/g) ? true : false);
    if (condition === true) {
      var tmp = $('a').css('-webkit-tap-highlight-color');
      $('body').css('-webkit-tap-highlight-color', 'rgba(0, 0, 0, 0)').css('cursor', 'pointer');
      $('a').css('-webkit-tap-highlight-color', tmp);
    }
  });

})(jQuery);
